.error-filter__container {
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: url("../../../assets/images/svg/arrow-down-for-native-select.svg");

    position: absolute;
    top: 32px;
    right: 5px;
  }

  select {
    appearance: none;
    padding: 0 5px;
  }

  span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  .additional-filters__select {
    cursor: pointer;
    color: rgba(4, 13, 23, 0.5);
    border: 1px solid transparent;
    background-color: rgba(4, 13, 23, 0.1);
    width: 250px;
    height: 38px;

    option {
      color: rgba(4, 13, 23, 0.5);
    }
  }
}

.reboot-dropdown-wrapper {
  margin-right: 15px;

  .drop-down-list-reboot .drop-down-list-reboot-btn {
    max-height: 38px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.install-firmware-btn .is-large-button {
  max-height: 38px;
}