.maintenance-container {
    color: #040d17;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    span {
        font-size: 16px;
        color: rgba(4, 13, 23, 0.5);
    }

    & > span:nth-child(4) {
        margin-bottom: 10px;
    }
}
