.documentation-wrapper {
  .upload-modal-header {
    align-items: center;
    padding: 0 40px !important;
    margin-bottom: 30px !important;

    .close-btn {
      margin-right: 0;
    }
  }

  .modal-content {
    background-color: #ececed;
  }

  .upload-section {
    background-color: #ececed;
  }

  .upload-modal-body {
    padding: 0 !important;
    min-height: 120px;

    .files-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #040d17;
      gap: 5px;
      padding: 0px 40px 0 40px;

      .file-size {
        font-weight: 400;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        word-break: break-all;
        padding: 10px;
        padding-right: 21px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);

        .close-btn {
          margin-right: 0px;
          width: 11px;
        }

        button {
          background-color: transparent !important;
        }
      }
    }

    .ant-upload {
      border: none !important;
      background-color: #ececed;
    }

    .ant-upload-drag-container {
      padding: 0 40px;
      display: flex;
      min-height: 120px;
      align-items: center;
      justify-content: space-between;

      .drop-file-text {
        align-items: center;
        color: #040d17;
        opacity: 0.5;
        font-size: 12px;
        margin-top: 5px;

        &.transparency {
          opacity: 0;
        }
      }

      .exportBtn {
        min-width: 150px;
      }

      .chooseFile-btn:hover {
        background-color: #95c11f !important;
        border-color: #95c11f !important;
      }
    }
  }

  .modal-content .modal-header .modal-title {
    margin-left: 0 !important;
  }
}
