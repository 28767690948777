.cancel-button {
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;

    width: auto;
    height: 39px;
    padding: 0.65rem 1rem;
    margin: 0;

    border: 1px solid #95c11f;
    border-radius: 60px;

    font-size: 14px;
    font-weight: 600;

    line-height: 0.9;
    color: #040d17;
    cursor: pointer;

    &:hover:not(:disabled):not(.active) {
        background-color: #95c11f;
        color: #040d17;
    }

    &:focus {
        border-color: #95c11f;
        background-color: #95c11f;
        color: #040d17;
    }
}

.nav-settings {
    .cancel-button {
        color: #fff;
    }
}
