.changeColumn {
  .modal-body {
    .switch-container {
      display: flex;
      flex-direction: row;
      gap: 1em;
    }

    .tarif-header {
      color: #040d17;
      padding-top: 2px;
    }
  }
}
