.myModalWindow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;

    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-out;

  opacity: 0;
  visibility: hidden;

  .modalContainer {
    position: relative;
    transition: inherit;
    padding: 0 !important;
    min-width: 500px;

    .modal-dialog {
      transition: inherit;
      box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2);
      transform: translateY(-100%);
      max-height: 100vh;
      min-width: 650px;
    }
  }

  &.addDevice,
  &.editRow {
    .modalContainer {
      .modal-dialog {
        min-width: 500px;
      }
    }
  }

  &.historicalTariff,
  &.tariffs,
  &.editTariff {
    .modalContainer {
        position: relative;
        transition: inherit;
        padding: 0 !important;
        min-width: 500px;

        .modal-dialog {
            transition: inherit;
            box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
            transform: translateY(-100%);
            max-height: 100vh;
        }
    }

    &.editTariff {
        .modalContainer {
            .modal-dialog {
                min-width: 650px;
            }
        }
    }
  }

  &.editSingleStringModal,
  &.editStringPerformance {
    .modalContainer {
      .modal-dialog {
        min-width: 998px;

        .customTextField .inputRoot.Mui-disabled input {
          opacity: 0.5;
        }
      }
    }
  }
}

.myModalWindow.opened {
  opacity: 1;
  visibility: visible;

  .modalContainer {
    .modal-dialog {
      transform: translateY(0);
    }
  }
}

.text-for-changmail {
    opacity: 1;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.42px;
    padding: 35px 25px;
}
