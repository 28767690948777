.display-none {
  display: none !important;
}

span.thank_you_email {
  color: #fff;
  cursor: pointer;
}

.thank_you_email-content {
  backdrop-filter: blur(37.5px);
  background: rgba(32, 44, 45, 0.85);
  border-radius: 24px;
  min-width: 100%;
  padding: 70px 10% 40px;
  .btn-upd-dashb button {
    color: #fff !important;
  }
}

.thank_you_email-wrapper {
  flex: 0 1 70%;
}

.thank_you_email-container {
  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}
