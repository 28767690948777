.form-control-multiple-select {
  width: 100%;

  .input-label-multiple-select {
    margin-bottom: 0;
    color: #040d17;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.39px;
  }

  .MuiSelect-select {
    &:focus {
      background: none;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: #95c11f;
  }

  .MuiInput-underline::before,
  .MuiInput-underline:hover::before,
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border: none;
  }

  .MuiInput-underline,
  .MuiInput-underline::after,
  .MuiInput-underline:hover::after,
  .MuiInput-underline:focus::after {
    border-bottom: 1px solid #5e6774;
  }

  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    color: #040d17;
  }

  .affected-device {
    color: #040d17;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.48px;
    box-shadow: none;
    line-height: 24px;
    display: block;
  }

  .multiple-select-icon {
    top: 0;
    right: -1px;
    fill: #999999;
    width: 24px;
    height: 24px;
  }

  .multiple-select-helper-text {
    font-size: 11px;
    margin: 4px 0 0;
  }

  .validation-error {
    color: #f4516c !important;
  }
}

#menu-authorizedCountries,
#menu-affectedDevices,
#menu-controlled_devices,
#menu-limitedFeedIn\.controlled_devices,
#menu-negativePricesFeedIn\.controlled_devices {
  .multiple-select-menu {
    background-color: #fff !important;
    max-width: 350px;
    max-height: 170px;
    margin-top: 1px;
  }

  .MuiPaper-rounded {
    border-radius: 0 !important;
    border: 1px solid #40424d;
  }

  .MuiMenuItem-root.Mui-selected,
  .MuiMenuItem-root.Mui-selected:hover {
    background: none;
  }

  .MuiMenu-list {
    li.MuiMenuItem-root {
      margin: 0 !important;
      padding: 0 !important;

      &:not(:last-child) {
        .labeled-checkbox-container {
          border-bottom: 1px solid #5e6774;
        }
      }

      .labeled-checkbox-container {
        display: flex;
        align-items: center;
        margin: 0 6px 0 20px !important;
        padding: 0 !important;
        width: 100%;
      }

      .multiple-select-checkbox {
        padding: 3px 0 3px 0 !important;
      }

      .multiple-select-checkbox-checked {
        color: var(--primary-color);
      }

      .PrivateSwitchBase-root-2 {
        padding: 3px;
      }

      .MuiButtonBase-root {
        .PrivateSwitchBase-input-5 {
          border: none;
        }
      }

      .multiple-select-label {
        margin: 0;
        padding-left: 18px;
      }

      .multiple-select-label-primary {
        color: #040d17;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.48px;
        box-shadow: none;
        line-height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }

    .MuiCheckbox-colorPrimary {
      color: #40424d;
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: var(--primary-color);
    }
  }
}

.countries-select {
  .MuiSelect-select {
    color: #040d17 !important;
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba($color: #040d17, $alpha: 0.5) !important;

    &:before {
      border-bottom: none !important;
    }
  }
}

#menu-authorizedCountries {
  .multiple-select-menu {
    background-color: #40424d !important;
  }

  .MuiMenu-list {
    .MuiCheckbox-colorPrimary {
      color: rgba($color: #fff, $alpha: 0.5);
    }
  }

  .MuiPaper-rounded {
    border: 1px solid transparent;
  }

  .labeled-checkbox-container {
    border-bottom: none !important;
  }
}
